<template>
  <BCard>
    <div class="d-flex text-black text-2xl font-semibold mb-2">
      <span>Role User</span>
      <BButton
        tag="router-link"
        :to="{
          name: $route.meta.routeCreate,
        }"
        variant="primary"
        class="ml-auto px-2"
      >
        <strong>+ Tambah</strong>
      </BButton>
    </div>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 210px); overflow-y: scroll"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(total_access)="data">
            <div
              class="underline cursor-pointer text-[#F95031]"
              @click="getDetailData(data.item.id)"
            >
              {{ data.item.total_access }} Akses
            </div>
          </template>
          <template #cell(action)="data">
            <div class="flex justify-center items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <span
                  class="k-edit h-100 font-semibold text-12"
                  style="color: #FBA63C;"
                />
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ModalDetail
      :selected="detailData"
      :role-name="roleName"
    />
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableField } from './config'
import ModalDetail from './ModalDetail.vue'

export default {
  components: {
    ModalDetail,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      detailData: [],
      fields: tableField,
      roleName: '',
      alertError,
      alertSuccess,
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}`
      const url = `v1/role_access?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}`
        const url = `v1/role_access?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async getDetailData(id) {
      const url = `v1/role_access/${id}/detail`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailData = data.menu_access.map(e => e.id)
          this.roleName = data.role_access_name

          this.$bvModal.show('modalDetail')
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
