import { DAY_MONTH_YEAR } from '@/libs/filterDate'

// eslint-disable-next-line import/prefer-default-export
export const tableField = [
  {
    key: 'role_access_name',
    label: 'Nama Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'total_access',
    label: 'Jumlah Akses',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
  },
]
