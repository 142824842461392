<template>
  <BModal
    id="modalDetail"
    ref="modalDetail"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="text-center my-1">
      <div class="flex mb-1">
        <span class="font-semibold text-[16px]">Akses Role "{{ roleName }}"</span>
        <span
          class="k-close text-black text-[32px] ml-auto cursor-pointer"
          @click="$bvModal.hide('modalDetail')"
        />
      </div>
      <div class="custom-scroll overflow-y-auto h-[calc(100vh_-_200px)]">
        <div
          v-for="(menu, i) in menus"
          :key="i"
        >
          <div
            v-b-toggle="`menu-${i}`"
            class="d-flex w-100 items-center py-[24px]"
          >
            <span
              class="k-arrow-down-1"
              :class="menu.sub_menu.length > 0 ? '' : 'text-zinc-300'"
            />
            <span class="d-flex ml-[12px] font-bold">{{ menu.name }}</span>
            <BFormCheckbox
              v-model="menuSelected"
              :value="menu.id"
              class="ml-auto"
              disabled
            />
          </div>
          <b-collapse
            v-if="menu.sub_menu.length > 0"
            :id="`menu-${i}`"
            visible
          >
            <div
              v-for="(child, idx) in menu.sub_menu"
              :key="idx + 'a'"
              class="d-flex w-100 items-center py-[24px]"
            >
              <span class="border-l h-[20px] w-[15px]" />
              <span class="d-flex ml-[12px]">{{ child.name }}</span>
              <BFormCheckbox
                v-model="menuSelected"
                :value="child.id"
                class="ml-auto"
                disabled
              />
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  props: {
    roleName: {
      type: String,
      default: '',
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      alertError,
      menus: [],
      allMenuExist: [],
      menuSelected: [],
      selectAll: false,
    }
  },
  watch: {
    selected(newVal) {
      this.menuSelected = newVal
    },
    menuSelected(newVal) {
      this.selectAll = newVal.length === this.allMenuExist.length
    },
  },
  mounted() {
    this.getListData()
  },
  methods: {
    async getListData() {
      const url = 'v1/menu_access'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.menus = data

          data.forEach(e => {
            this.allMenuExist.push(e.id)
            if (e.sub_menu.length) {
              e.sub_menu.forEach(el => this.allMenuExist.push(el.id))
            }
          })
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    updateMenuSelected() {
      this.$emit('update:menuSelected', this.menuSelected)
    },
    checkAll(event) {
      if (event) {
        this.menuSelected = this.allMenuExist
      } else {
        this.menuSelected = []
      }
    },
  },
}
</script>
